<template>
  <b-row>
    <b-col
      cols="12"
      sm="12"
      lg="12"
    >
      <b-row
        class="justify-content-center "
        style="gap:3%;"
      >
        <b-card style="background: #f3f3f3; width: 360px;">
          <b-col>
            <b-row>
              <b-form-group
                class="font-weight-bold d-flex flex-1 justify-content-between align-items-center  "
                style=" width: 100%;"
                :label="`${$t('customer.lastName')} ${isEditActive}`"
                label-for="last-name"
              >
                <validation-provider
                  #default="validationContext"
                  name="Last Name"
                  rules="required"
                  mode="eager"
                >
                  <div v-if="readonlys === false">
                    <b-form-input
                      id="last-name"
                      v-model="customerDataInfo.lastName"
                      :disabled="readonlys"
                      :state="
                        getValidationState(validationContext) === false
                          ? false
                          : null
                      "
                      maxlength="30"
                      style="max-width: 190px"
                      lazy-formatter
                      :formatter="trimInput"
                      :placeholder="$t('customer.lastName')"
                    />
                  </div>
                  <div v-else>
                    <span>{{ customerDataInfo.lastName || '-' }}</span>
                  </div>
                  <b-form-invalid-feedback
                    :state="
                      getValidationState(validationContext) === false
                        ? false
                        : null
                    "
                    style="max-width: 190px"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-row>
          </b-col>

          <b-col>
            <b-row>
              <b-form-group
                class="font-weight-bold d-flex flex-1 justify-content-between align-items-center  "
                :label="`${$t('customer.firstName')} ${isEditActive}`"
                style="width: 100%;"
                label-for="first-name"
              >
                <validation-provider
                  #default="validationContext"
                  name="First Name"
                  rules="required"
                  mode="eager"
                >
                  <div v-if="readonlys === false">
                    <b-form-input
                      id="first-name"
                      v-model="customerDataInfo.firstName"
                      :disabled="readonlys"
                      :state="
                        getValidationState(validationContext) === false
                          ? false
                          : null
                      "
                      style="max-width: 190px"
                      maxlength="30"
                      lazy-formatter
                      :formatter="trimInput"
                      :placeholder="$t('customer.firstName')"
                    />
                  </div>
                  <div v-else>
                    <span>{{ customerDataInfo.firstName || '-' }}</span>
                  </div>
                  <b-form-invalid-feedback
                    :state="
                      getValidationState(validationContext) === false
                        ? false
                        : null
                    "
                    style="max-width: 190px"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-row>
          </b-col>

          <b-col>
            <b-row>
              <b-form-group
                class="font-weight-bold d-flex justify-content-between align-items-center align-items-center"
                :label="`${$t('customer.nickName')} `"
                style="width: 100%;"

                label-for="nick-name"
              >
                <div
                  v-if="readonlys === false"
                  class="d-flex justify-content-end"
                >
                  <b-form-input
                    id="nick-name"
                    v-model="customerDataInfo.nickName"
                    :disabled="readonlys"
                    maxlength="30"
                    lazy-formatter
                    :formatter="trimInput"
                    :placeholder="$t('customer.nickName')"
                  />
                </div>
                <div v-else>
                  <span>{{ customerDataInfo.nickName || '-' }}</span>
                </div>
              </b-form-group>
            </b-row>
          </b-col>

          <b-col>
            <b-row>
              <b-form-group
                class="font-weight-bold d-flex justify-content-between align-items-center align-items-center"
                :label="`${$t('customer.createdAt')}`"
                style="width: 100%;"

                label-for="createdAt"
              >
                <div
                  v-if="readonlys === false"
                  class="d-flex justify-content-end"
                >
                  <flat-pickr
                    v-model="customerDataInfo.createdAt"
                    class="d-none"
                  />
                  <flat-pickr
                    id="createdAt"
                    v-model="customerDataInfo.createdAt"
                    :disabled="true"
                    :placeholder="$t('customer.createdAt')"
                    class="form-control mb-0 "
                    style="background: #e0e0e0  !important;"
                    :config="{
                      allowInput: true,
                      dateFormat: 'Y-m-d',
                      altFormat: 'd-m-Y',
                      locale: locale === 'vi' ? Vietnamese : null,
                      altInput: true,
                      disableMobile: true,
                    }"
                  />
                </div>
                <div v-else>
                  <span>{{ getDate(customerDataInfo.createdAt) || '-' }}</span>
                </div>
              </b-form-group>
            </b-row>
          </b-col>

          <b-row>
            <b-col>
              <div class="d-flex align-items-center justify-content-between align-items-center ">
                <h6 class="text-body m-0">
                  Mạng xã hội
                </h6>
                <div v-if="readonlys === false">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.hover.v-primary.window
                    title="Chỉnh sửa"
                    size="sm"

                    variant="outline-info"
                    class="btn-icon  py-25 px-50 d-flex-center"
                    @click="editSocialNetwork(customerDataInfo)"
                  >
                    <img src="@icons/edit.svg">
                    <span
                      v-if="!isMobileView"
                      class="font-medium-2 ml-25 "
                      style="margin-top: 1px; display: flex; justify-content: center;"
                    />
                  </b-button>
                </div>
                <div
                  v-else
                  class="d-none"
                />
              </div>

              <div v-if="customerDataInfo.socialAccounts">
                <div
                  v-for="(item, i) in customerDataInfo.socialAccounts"
                  :key="i"
                  class="mb-50 d-flex align-items-center ml-1"
                >
                  <span
                    class="mr-50 font-weight-bolder"
                    style="min-width: 120px"
                  >
                    {{ item.type }}
                  </span>

                  <b-link
                    class="url-ellipsis"
                    :href="item.url"
                    target="_blank"
                  >
                    {{ item.url }}
                  </b-link>
                </div>
              </div>

              <div v-else>
                Chưa thêm mạng xã hội
              </div>
              <ModalEditSocialNetwork
                v-if="customerDataInfo"
                :social-accounts.sync="customerDataInfo.socialAccounts"
                :customer-data="customerDataInfo"
              />
            </b-col>
          </b-row>
        </b-card>
        <b-card style="background: #f3f3f3;width: 360px;">
          <b-row>
            <b-col>
              <b-form-group
                class="font-weight-bold d-flex justify-content-between align-items-center "
                :label="`${$t('customer.phoneNumber')} ${isEditActive}`"
                style="width: 100%;"
                label-for="phone-number"
              >
                <validation-provider
                  #default="validationContext"
                  name="Phone Number"
                  :rules="mode === 'add' || mode === 'edit' ? `required|phoneNumber|isUnique:customer,phoneNumber,${customerDataInfo.phoneNumber}` : ''"
                  mode="eager"
                >
                  <div
                    v-if="readonlys === false"
                    class="d-flex justify-content-end"
                  >
                    <b-form-input
                      id="phone-number"
                      v-model="customerDataInfo.phoneNumber"
                      v-remove-non-numeric-chars.allNumber
                      class="ml-1"
                      :state="getValidationState(validationContext) === false ? false : null"
                      maxlength="30"
                      style="max-width: 190px"

                      lazy-formatter
                      :formatter="trimInput"
                      :placeholder="$t('customer.phoneNumber')"
                    />
                    <!-- <b-button
                      variant="outline-info"
                      class="ml-50 px-1 text-nowrap"
                      :disabled="!isActiveAddMorePhone || getValidationState(validationContext) === false"
                      @click="addMorePhoneHandle"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button> -->
                  </div>
                  <div v-else>
                    <span class="font-weight-bold">{{ customerDataInfo.phoneNumber }}</span>
                  </div>

                  <b-form-invalid-feedback
                    style="max-width: 190px"
                    :state="getValidationState(validationContext) === false ? false : null"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group
                class="font-weight-bold d-flex justify-content-between align-items-center "
                :label="`${$t('customer.emailAddress')} ${isEditActive}`"
                style="width: 100%;"
                label-for="email"
              >
                <validation-provider
                  #default="validationContext"
                  name="Email"
                  :rules="mode === 'add' || mode === 'edit' ? `required|email|isUnique:customer,email,${customerDataInfo.emailAddress}` : ''"
                  mode="eager"
                >
                  <div v-if="readonlys === false">
                    <b-form-input
                      id="email"
                      v-model="customerDataInfo.emailAddress"
                      :disabled="readonlys"
                      :state="getValidationState(validationContext) === false ? false : null"
                      maxlength="30"
                      style="max-width: 190px"
                      lazy-formatter
                      :formatter="trimInput"
                      :placeholder="$t('customer.emailAddress')"
                    />
                  </div>
                  <div v-else>
                    <span>{{ customerDataInfo.emailAddress || '-' }}</span>
                  </div>
                  <b-form-invalid-feedback
                    style="max-width: 190px"
                    :state="getValidationState(validationContext) === false ? false : null"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group
                class="font-weight-bold d-flex justify-content-between align-items-center align-items-center"
                :label="`${$t('customer.Birthday')}`"
                style="width: 100%;"
                label-for="birthDay"
              >
                <div
                  v-if="readonlys === false"
                  class="d-flex justify-content-end"
                >
                  <flat-pickr
                    v-model="customerDataInfo.birthDay"
                    class="d-none"
                  />
                  <flat-pickr
                    id="birthDay"
                    v-model="customerDataInfo.birthDay"
                    :disabled="readonlys"
                    :placeholder="$t('customer.Birthday')"
                    class="form-control mb-0 bg-white"
                    :config="{
                      allowInput: true,
                      locale: locale === 'vi' ? Vietnamese : null,
                      dateFormat: 'Y-m-d',
                      altFormat: 'd-m-Y',
                      altInput: true,
                      disableMobile: true,
                      maxDate: 'today',
                    }"
                  />
                </div>
                <div v-else>
                  <span>{{ getDate(customerDataInfo.birthDay) || '-' }}</span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group
                class="font-weight-bold d-flex justify-content-between align-items-center align-items-center"
                :label="`${$t('customer.Gender')} ${isEditActive}`"
                style="width: 100%;"
                label-for="gender"
              >
                <validation-provider
                  #default="validationContext"
                  name="Gender"
                  rules="required"
                  mode="eager"
                >
                  <div v-if="readonlys === false">
                    <v-select
                      v-model="customerDataInfo.gender"
                      label="label"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      style="max-width: 190px; background: #ffff; border-radius: 10px;"
                      :options="genderOptions"
                      :reduce="(val) => val.value"
                      class="fixed-width-select"
                      :clearable="false"
                      :disabled="readonlys"
                      input-id="gender-type"
                      :placeholder="$t('customer.gender')"
                    >
                      <template #option="{ value }">
                        <span>{{ $t(value) }}</span>
                      </template>
                      <template #selected-option="{ value }">
                        <span>{{ $t(value) }}</span>
                      </template>
                      <template #no-options>
                        {{ $t('customer.noOptions') }}
                      </template>
                    </v-select>
                  </div>
                  <div v-else>
                    <span>{{ $t(`${customerDataInfo.gender}`) || '-' }}</span>
                  </div>
                  <b-form-invalid-feedback :state="getValidationState(validationContext) === false ? false : null">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- <b-row
            v-for="(phone, index) in customerDataInfo.backupPhoneNumbers"
            :key="index"
          >
            <validation-provider
              #default="validationContext"
              name="Backup Phone"
              :rules="mode === 'add' || mode === 'edit' ? `required|phoneNumber|isUnique:customer,phonenumber|duplicatePhone:${
                customerDataInfo.phoneNumber
              },${customerDataInfo.backupPhoneNumbers.toString()}` : ''"
            >
              <b-form-group
                class="font-weight-bold"
                :label="`${$t('customer.backupPhoneNumber')} ${index + 1} ${isEditActive}`"
                :label-for="`backup-phone-${index}`"
              >
                <div class="d-flex">
                  <b-form-input
                    :id="`backup-phone-${index}`"
                    v-model="customerDataInfo.backupPhoneNumbers[index]"
                    v-remove-non-numeric-chars.allNumber
                    :disabled="readonlys"
                    style="width: 100%;"
                    :state="getValidationState(validationContext) === false ? false : null"
                    :debounce="300"
                    :formatter="trimInput"
                    :placeholder="$t('customer.placeholderPhoneNumber')"
                  />
                  <b-button
                    v-if="!readonlys"
                    variant="flat-danger"
                    class="ml-50 px-50 text-nowrap"
                    @click="removePhoneHandle(index)"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </div>
                <b-form-invalid-feedback :state="getValidationState(validationContext) === false ? false : null">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-row> -->
        </b-card>
      </b-row>
    </b-col>
    <b-col
      cols="12"
      sm="12"
      lg="12"
    >
      <b-row
        class="justify-content-center "
        style="gap: 3%;"
      >
        <b-card
          class="mb-1"
          style="background: #f3f3f3; width: 360px;"
        >
          <b-col>
            <b-row>
              <b-form-group
                class="font-weight-bold d-flex justify-content-between align-items-center align-items-center"
                style="width: 100%;"
                :label="`${$t('customer.identifyNumber')} `"
                label-for="identify-number"
              >
                <!-- <validation-provider
                  #default="validationContext"
                  name="identifyNumber "
                  style="width: 100%; position: relative;"
                  :rules="getValidateIdentify(customerDataInfo,'identifyNumber')"
                  mode="aggressive"
                > -->
                <div v-if="readonlys === false">
                  <b-form-input
                    id="identify-number"
                    v-model="customerDataInfo.identifyNumber"
                    v-remove-non-numeric-chars.allNumber
                    :disabled="readonlys"
                    maxlength="30"
                    lazy-formatter
                    :formatter="trimInput"
                    :placeholder="$t('customer.identifyNumber')"
                  />
                </div>
                <div v-else>
                  <span>{{ customerDataInfo.identifyNumber || '-' }}</span>
                </div>
                <!-- <b-form-invalid-feedback
                    style="max-width: 190px;"
                    :state="
                      getValidationState(validationContext) === false
                        ? false
                        : null
                    "
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider> -->
              </b-form-group>
            </b-row>
          </b-col>

          <b-col>
            <b-row>
              <b-form-group
                class=" font-weight-bold d-flex justify-content-between align-items-center align-items-center "
                style="width: 100%;"
                :label="`${$t('customer.Dateofissue')}`"
                label-for="Dateofissue"
              >
                <!-- <validation-provider
                  #default="validationContext"
                  name="startDate"
                  style="width: 100%; position: relative;"
                  :rules="getValidateIdentify(customerDataInfo,'startDate')"
                  mode="aggressive"
                > -->
                <div v-if="readonlys === false">
                  <div v-if="customerDataInfo?.identifyConfig">
                    <flat-pickr
                      v-model="customerDataInfo.identifyConfig.startDate"
                      class="d-none"
                    />
                    <flat-pickr
                      id="Dateofissue"
                      v-model="customerDataInfo.identifyConfig.startDate"
                      :disabled="readonlys"
                      :placeholder="$t('customer.Dateofissue')"
                      class="mb-0 bg-white"
                      :config="{
                        allowInput: true,
                        locale: locale === 'vi' ? Vietnamese : null,
                        maxDate: 'today',
                        dateFormat: 'Y-m-d',
                        altFormat: 'd-m-Y',
                        altInput: true,
                        disableMobile: true,
                      }"
                    />
                  </div>
                  <div v-else>
                    <span>-</span>
                  </div>
                </div>

                <div v-else>
                  <span>{{ getDate(customerDataInfo?.identifyConfig?.startDate) || '-' }}</span>
                </div>
                <!-- <b-form-invalid-feedback
                    style="max-width: 190px;"
                    :state="
                      getValidationState(validationContext) === false
                        ? false
                        : null
                    "
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider> -->
              </b-form-group>
            </b-row>
          </b-col>
          <b-col>
            <b-row>
              <b-form-group
                class=" font-weight-bold d-flex justify-content-between align-items-center align-items-center "
                :label="`${$t('customer.expirationDate')}`"
                label-for="expirationDate"
                style="width: 100%;"
              >
                <!-- <validation-provider
                  #default="validationContext"
                  name="endDate"
                  style="width: 100%; position: relative;"
                  :rules="getValidateIdentify(customerDataInfo,'endDate')"
                  mode="aggressive"
                > -->
                <div v-if="readonlys === false">
                  <div v-if="customerDataInfo?.identifyConfig">
                    <flat-pickr
                      v-model="customerDataInfo.identifyConfig.endDate"
                      class="d-none"
                    />
                    <flat-pickr
                      id="expirationDate"
                      v-model="customerDataInfo.identifyConfig.endDate"
                      :disabled="readonlys"
                      class="bg-white"
                      :placeholder="$t('customer.expirationDate')"
                      :config="{
                        allowInput: true,
                        locale: locale === 'vi' ? Vietnamese : null,
                        minDate: 'today',
                        dateFormat: 'Y-m-d',
                        altFormat: 'd-m-Y',
                        altInput: true,
                        disableMobile: true,
                      }"
                    />
                  </div>
                  <div v-else>
                    <span>-</span>
                  </div>
                </div>
                <div v-else>
                  <span>{{ getDate(customerDataInfo?.identifyConfig?.endDate) || '-' }}</span>
                </div>
                <!-- <b-form-invalid-feedback
                    style="max-width: 190px;"
                    :state="
                      getValidationState(validationContext) === false
                        ? false
                        : null
                    "
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider> -->
              </b-form-group>
            </b-row>
          </b-col>
          <b-col>
            <b-row>
              <b-form-group
                class="font-weight-bold d-flex  justify-content-between align-items-center align-items-center"
                style="width: 100%;"
                :label="`${$t('customer.Placeofissue')} `"
                label-for="placeOfIssue"
              >
                <!-- <validation-provider
                  #default="validationContext"
                  name="placeOfIssue"
                  style="width: 100%; position: relative;"
                  :rules="getValidateIdentify(customerDataInfo,'placeOfIssue')"
                  mode="aggressive"
                > -->
                <div v-if="readonlys === false">
                  <div v-if="customerDataInfo.identifyConfig">
                    <b-form-input
                      id="placeOfIssue"
                      v-model="customerDataInfo.identifyConfig.placeOfIssue"
                      :disabled="readonlys"

                      maxlength="30"
                      lazy-formatter
                      :formatter="trimInput"
                      :placeholder="$t('customer.Placeofissue')"
                    />
                  </div>
                  <div v-else>
                    <span>-</span>
                  </div>
                </div>
                <div v-else>
                  <span>{{ customerDataInfo?.identifyConfig?.placeOfIssue || '-' }}</span>
                </div>
                <!-- <b-form-invalid-feedback
                    style="max-width: 190px;"
                    :state="
                      getValidationState(validationContext) === false
                        ? false
                        : null
                    "
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider> -->
              </b-form-group>
            </b-row>
          </b-col>
          <div
            v-if="readonlys === false"
            class="d-flex justify-content-end"
          >
            <BButton
              v-b-tooltip.hover.focus.top="`Quét QR Code CCCD/Passport`"
              variant="flat-primary"
              class="rounded-circle p-50"
              @click="onClickQrScanHandle(`qr-code-scan-passport-identify`)"
            >
              <IAmIcon
                icon="qrCode"
                size="18"
                color="black"
              />
            </BButton>

            <IAmQrCodeScan
              :idProp="`qr-code-scan-passport-identify`"
              @apply="value => customerDataInfo.identifyNumber = value"
            />

            <BButton
              v-b-tooltip.hover.focus.top="`Tải Ảnh để quét QR Code CCCD/Passport`"
              variant="flat-primary"
              class="rounded-circle p-50"
              @click="onClickQrScanHandle(`qr-code-scan-by-upload-passport-identify`)"
            >
              <IAmIcon
                icon="cloudUploadOutline"
                size="18"
                color="black"
              />
            </BButton>

            <IAmQrCodeScanByUpload
              :idProp="`qr-code-scan-by-upload-passport-identify`"
              @apply="value => customerDataInfo.identifyNumber = value"
            />
          </div>
          <div
            v-else
            class="d-none"
          />
        </b-card>
        <b-card style="background: #f3f3f3; width: 360px;">
          <b-col>
            <b-row>
              <b-form-group
                class="font-weight-bold d-flex  justify-content-between align-items-center align-items-center"
                style="width: 100%;"
                :label="`${$t('customer.city')}`"
                label-for="city"
              >
                <div v-if="readonlys === false">
                  <v-select
                    id="city"
                    v-model="customerDataInfo.addressCity"
                    :reduce="val => val.id"
                    :options="optionsIdCity"
                    label="name"
                    class="fixed-width-select bg-white"
                    style="max-width: 190px; border-radius: 10px;"
                    :disabled="readonlys"
                    maxlength="30"
                    lazy-formatter
                    :formatter="trimInput"
                    :placeholder="$t('customer.city')"
                  >
                    <template #option="{ name }">
                      <span>{{ name }}</span>
                    </template>

                    <template #selected-option="{ name }">
                      <span>{{ name }}</span>
                    </template>

                    <template #no-options>
                      {{ $t('customer.noOptions') }}
                    </template>
                  </v-select>
                </div>
                <div v-else>
                  <span class="font-weight-bold">{{ customerDataInfo.addressCity?.name || '-' }}</span>
                </div>
              </b-form-group>
            </b-row>
          </b-col>

          <b-col>
            <b-row>
              <b-form-group
                :label="`${$t('customer.district')}`"
                style="width: 100%;"
                class="font-weight-bold d-flex justify-content-between align-items-center align-items-center"

                label-for="district"
              >
                <div
                  v-if="readonlys === false"
                  class="d-flex justify-content-end"
                >
                  <v-select
                    id="district"
                    v-model="customerDataInfo.addressDistrict"
                    class="fixed-width-select bg-white"
                    style="max-width: 190px; border-radius: 10px;"
                    :options="optionsIdDitric"
                    :reduce="val => val.id"
                    :disabled="readonlys"
                    label="name"
                    maxlength="30"
                    lazy-formatter
                    :formatter="trimInput"
                    :placeholder="$t('customer.district')"
                  >
                    <template #option="{ name }">
                      <span>{{ name }}</span>
                    </template>

                    <template #selected-option="{ name }">
                      <span>{{ name }}</span>
                    </template>

                    <template #no-options>
                      {{ $t('customer.noOptions') }}
                    </template>
                  </v-select>
                </div>
                <div v-else>
                  <span>{{ customerDataInfo.addressDistrict?.name || '-' }}</span>
                </div>
              </b-form-group>
            </b-row>
          </b-col>
          <b-col>
            <b-row>
              <b-form-group
                :label="`${$t('customer.ward')}`"
                style="width: 100%;"
                class="font-weight-bold d-flex justify-content-between align-items-center align-items-center"
                label-for="social-accounts"
              >
                <div
                  v-if="readonlys === false"
                  class="d-flex justify-content-end"
                  style="width: 100%;"
                >
                  <v-select
                    id="social-accounts"
                    v-model="customerDataInfo.addressWard"
                    :disabled="readonlys"
                    :reduce="val => val.id"
                    class="fixed-width-select bg-white"
                    style="max-width: 190px;  border-radius: 10px;"
                    label="name"
                    :options="optionsIdWard"
                    maxlength="30"
                    lazy-formatter
                    :formatter="trimInput"
                    :placeholder="$t('customer.ward')"
                  >
                    <template #option="{ name }">
                      <span>{{ name }}</span>
                    </template>

                    <template #selected-option="{ name }">
                      <span>{{ name }}</span>
                    </template>

                    <template #no-options>
                      {{ $t('customer.noOptions') }}
                    </template>
                  </v-select>
                </div>
                <div v-else>
                  <span>{{ customerDataInfo.addressWard?.name || '-' }}</span>
                </div>
              </b-form-group>
            </b-row>
          </b-col>
          <b-col>
            <b-row>
              <b-form-group
                class="font-weight-bold d-flex justify-content-between align-items-center align-items-center"
                :label="`${$t('customer.street')}`"
                style="width:100% ;"
                label-for="street"
              >
                <div v-if="readonlys === false">
                  <b-form-input
                    id="street"
                    v-model="customerDataInfo.addressStreet"
                    style=" background: #ffff; border-radius: 5px;"
                    :disabled="readonlys"
                    maxlength="30"
                    lazy-formatter
                    :formatter="trimInput"
                    :placeholder="$t('customer.street')"
                  />
                </div>
                <div v-else>
                  <span>{{ customerDataInfo.addressStreet || '-' }}</span>
                </div>
              </b-form-group>
            </b-row>
          </b-col>
        </b-card>
      </b-row>
    </b-col>
    <b-col
      cols="12"
      sm="12"
      lg="12"
    >
      <b-row
        class="justify-content-center"
        style="gap: 3%;"
      >
        <b-card
          class="mb-1 "
          style="background: #f3f3f3; width: 360px;"
        >
          <b-col>
            <b-row>
              <b-form-group
                class="font-weight-bold d-flex justify-content-between align-items-center align-items-center"
                style="width: 100%;"
                :label="`${$t('customer.passPort')}`"
                label-for="passportNumber"
              >
                <!-- <validation-provider
                  #default="validationContext"
                  name="passportNumber "
                  style="width: 100%; position: relative;"
                  :rules="getValidatePassport(customerDataInfo,'passportNumber')"
                  mode="aggressive"
                > -->
                <div
                  v-if="readonlys === false"
                  class="d-flex justify-content-end"
                >
                  <b-form-input
                    id="passportNumber"
                    v-model="customerDataInfo.passportNumber"
                    :disabled="readonlys"
                    maxlength="30"
                    lazy-formatter
                    :formatter="trimInput"
                    :placeholder="$t('customer.passPort')"
                  />
                </div>
                <div v-else>
                  <span>{{ customerDataInfo.passportNumber || '-' }}</span>
                </div>
                <!-- <b-form-invalid-feedback
                    style="max-width: 190px;"
                    :state="
                      getValidationState(validationContext) === false
                        ? false
                        : null
                    "
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider> -->
              </b-form-group>
            </b-row>
          </b-col>

          <b-col>
            <b-row>
              <b-form-group
                class="font-weight-bold d-flex  justify-content-between align-items-center align-items-center"
                :label="`${$t('customer.Dateofissue')}`"
                label-for="Dateofissue"
                style="width: 100%;"
              >
                <!-- <validation-provider
                  #default="validationContext"
                  name="startDate"
                  style="width: 100%;"
                  :rules="getValidatePassport(customerDataInfo,'startDate')"
                  mode="aggressive"
                > -->
                <div v-if="readonlys === false">
                  <div v-if="customerDataInfo.passportConfig ">
                    <flat-pickr
                      v-model="customerDataInfo.passportConfig.startDate"
                      class="d-none"
                    />
                    <flat-pickr
                      id="Dateofissue"
                      v-model="customerDataInfo.passportConfig.startDate"
                      :disabled="readonlys"
                      class="bg-white"
                      :placeholder="$t('customer.Dateofissue')"
                      :config="{
                        allowInput: true,
                        maxDate: 'today',
                        dateFormat: 'Y-m-d',
                        altFormat: 'd-m-Y',
                        altInput: true,
                        disableMobile: true,
                        locale: locale === 'vi' ? Vietnamese : null,

                      }"
                    />
                  </div>
                  <div v-else>
                    <span>-</span>
                  </div>
                </div>
                <div v-else>
                  <span>{{ getDate(customerDataInfo?.passportConfig?.startDate) || '-' }}</span>
                </div>
                <!-- <b-form-invalid-feedback
                    style="max-width: 190px;"
                    :state="
                      getValidationState(validationContext) === false
                        ? false
                        : null
                    "
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider> -->
              </b-form-group>
            </b-row>
          </b-col>
          <b-col>
            <b-row>
              <b-form-group
                class="font-weight-bold d-flex   justify-content-between align-items-center align-items-center"
                :label="`${$t('customer.expirationDate')}`"
                label-for="expirationDate"
                style="width: 100%;"
              >
                <!-- <validation-provider
                  #default="validationContext"
                  name="endDateCard "
                  style="width: 100%; position: relative;"
                  :rules="getValidatePassport(customerDataInfo,'endDate')"
                  mode="aggressive"
                > -->
                <div v-if="readonlys === false">
                  <div v-if="customerDataInfo.passportConfig">
                    <flat-pickr
                      v-model="customerDataInfo.passportConfig.endDate"
                      class="d-none"
                    />
                    <flat-pickr
                      id="expirationDate"
                      v-model="customerDataInfo.passportConfig.endDate"
                      :disabled="readonlys"
                      class="bg-white"
                      :placeholder="$t('customer.expirationDate')"
                      :config="{
                        allowInput: true,
                        minDate: 'today',
                        dateFormat: 'Y-m-d',
                        altFormat: 'd-m-Y',
                        altInput: true,
                        disableMobile: true,
                        locale: locale === 'vi' ? Vietnamese : null,

                      }"
                    />
                  </div>
                  <div v-else>
                    <span>-</span>
                  </div>
                </div>
                <div v-else>
                  <span>{{ getDate(customerDataInfo?.passportConfig?.endDate) || '-' }}</span>
                </div>
                <!-- <b-form-invalid-feedback
                    style="max-width: 190px;"
                    :state="
                      getValidationState(validationContext) === false
                        ? false
                        : null
                    "
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider> -->
              </b-form-group>
            </b-row>
          </b-col>
          <b-col>
            <b-row>
              <b-form-group
                class="font-weight-bold d-flex   justify-content-between align-items-center align-items-center"
                :label="`${$t('customer.Placeofissue')} `"
                style="width: 100%;"
                label-for="identify-number"
              >
                <!-- <validation-provider
                  #default="validationContext"
                  name="placeOfIssue"
                  style="width: 100%; position: relative;"
                  :rules="getValidatePassport(customerDataInfo,'placeOfIssue')"
                  mode="aggressive"
                > -->
                <div v-if="readonlys === false">
                  <div v-if="customerDataInfo.passportConfig">
                    <IAmCountrySelect
                      :id="'identify-number'"
                      v-model="customerDataInfo.passportConfig.placeOfIssue"
                      :disabled="readonlys"
                      class="bg-white fixed-width-select"
                      style=" max-width: 190px; border-radius: 10px;"
                      :placeholder="$t('customer.Placeofissue')"
                    />
                  </div>
                  <div v-else>
                    <span>-</span>
                  </div>
                </div>
                <div v-else>
                  <span v-if="customerDataInfo?.passportConfig?.placeOfIssue">
                    {{
                      customerDataInfo?.passportConfig?.placeOfIssue?.countryName
                        ? `${customerDataInfo?.passportConfig?.placeOfIssue?.countryName}(${customerDataInfo?.passportConfig?.placeOfIssue?.longCode})`
                        : customerDataInfo?.passportConfig?.placeOfIssue
                    }}
                  </span>
                  <span v-else>-</span>
                </div>
                <!-- <b-form-invalid-feedback
                    style="max-width: 190px;"
                    :state="
                      getValidationState(validationContext) === false
                        ? false
                        : null
                    "
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider> -->
              </b-form-group>
            </b-row>
          </b-col>
          <b-col>
            <b-row>
              <b-form-group
                class="font-weight-bold d-flex mb-0  justify-content-between align-items-center align-items-center"
                :label="`${$t('customer.nationality')} `"
                style="width: 100%;"
                label-for="nationality"
              >
                <!-- <validation-provider
                  #default="validationContext"
                  name="nationality"
                  style="width: 100%; position: relative;"
                  :rules="getValidatePassport(customerDataInfo,'nationality')"
                  mode="aggressive"
                > -->
                <div v-if="readonlys === false">
                  <div v-if="customerDataInfo.passportConfig">
                    <IAmCountrySelect
                      :id="'city'"
                      v-model="customerDataInfo.passportConfig.nationality"
                      class="bg-white fixed-width-select"
                      style=" max-width: 190px; border-radius: 10px;"
                      :disabled="readonlys"
                      :placeholder="$t('customer.nationality')"
                    />
                  </div>
                  <div v-else>
                    <span>-</span>
                  </div>
                </div>
                <div v-else>
                  <span v-if="customerDataInfo?.passportConfig?.nationality">
                    {{
                      customerDataInfo?.passportConfig?.nationality?.countryName ?
                        `${customerDataInfo?.passportConfig?.nationality?.countryName}(${customerDataInfo?.passportConfig?.nationality?.longCode})`
                        : customerDataInfo?.passportConfig?.nationality
                    }}
                  </span>
                  <span v-else>-</span>
                </div>
                <!-- <b-form-invalid-feedback
                    style="max-width: 190px;"
                    :state="
                      getValidationState(validationContext) === false
                        ? false
                        : null
                    "
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider> -->
              </b-form-group>
            </b-row>
          </b-col>
        </b-card>
        <b-card
          class="mb-1 "
          style="background: #f3f3f3  ;width: 360px; "
        >
          <b-col>
            <b-row>
              <b-form-group
                class="d-flex align-items-center  justify-content-between align-items-center font-weight-bold"
                style="width: 100%;"
                :label="`${$t('customer.receivables')} `"
                label-for="receivables"
              >
                <div
                  v-if="readonlys === false"
                  class=" d-flex justify-content-end"
                >
                  <b-form-input
                    id="receivables"
                    v-model="customerDataInfo.receivables"
                    :disabled="readonly"
                    maxlength="30"
                    lazy-formatter
                    :formatter="trimInput"
                    :placeholder="$t('customer.receivables')"
                  />
                </div>
                <div v-else>
                  <span class="text-info">{{ formatCurrency( customerDataInfo.receivables) || '-' }}</span>
                </div>
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group
                class="d-flex align-items-center  justify-content-between align-items-center font-weight-bold"
                style="width: 100%;"
                :label="`${$t('customer.profit')}`"
                label-for="profit"
              >
                <div v-if="readonlys === false">
                  <b-form-input
                    id="profit"
                    v-model="customerDataInfo.profit"
                    :disabled="readonly "
                    maxlength="30"
                    lazy-formatter
                    :formatter="trimInput"
                    :placeholder="$t('customer.profit')"
                  />
                </div>
                <div v-else>
                  <span class=" text-info">{{ formatCurrency(customerDataInfo.profit ) }}</span>
                </div>
              </b-form-group>
            </b-row>
          </b-col>
        </b-card>
      </b-row>
    </b-col>
    <!-- ANCHOR - Mạng xã hội -->

    <b-col
      cols="12"
      sm="12"
      lg="12"
    >
      <b-row class="justify-content-center">
        <b-card
          class="mx-2"
          style="background: #f3f3f3 ; width: 100%;"
        >
          <div v-if="readonlys === false">
            <b-form-textarea
              id="note"
              v-model="customerDataInfo.note"
              lazy-formatter
              :disabled="readonlys"
              style="width: 100%;"
              :formatter="trimInput"
              :placeholder="$t('customer.note')"
              rows="4"
              no-auto-shrink
            />
          </div>
          <div v-else>
            <span>{{ customerDataInfo.note || '-' }}</span>
          </div>
        </b-card>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormInvalidFeedback, BCard, BCol, BRow, BFormGroup, BFormInput, BFormTextarea, BButton, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import {
  ref, computed, watch, onMounted,
} from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'

import store from '@/store'
import VueI18n from '@/libs/i18n'
import { getUserData } from '@/api/auth/utils'
import { genderOptions } from '@/constants/selectOptions'
import formValidation from '@/@core/comp-functions/forms/form-validation'
import { apiCustomer } from '@/api'
import IAmCountrySelect from '@/components/IAmCountrySelect.vue'
import IAmQrCodeScan from '@/components/IAmQrCodeScan.vue'
import IAmQrCodeScanByUpload from '@/components/IAmQrCodeScanByUpload.vue'

import { trimInput } from '@core/comp-functions/forms/formatter-input'
import { dateTime, formatCurrency, getDate } from '@core/utils/filter'

import ModalEditSocialNetwork from './ModalEditSocialNetwork.vue'

export default {
  components: {
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    IAmQrCodeScan,
    IAmQrCodeScanByUpload,
    ValidationProvider,
    BFormInvalidFeedback,
    BFormTextarea,
    ModalEditSocialNetwork,
    IAmCountrySelect,
    BButton,
    vSelect,
    flatPickr,
  },
  props: {
    dataCustomerEdit: {
      type: Object,
      required: true,
    },
    readonlys: {
      type: Boolean,
      default: true,

    },
    mode: {
      type: String,
      default: 'edit',
    },
  },
  setup(props, { root, emit }) {
    const readonly = ref(true)
    const optionsIdCity = ref([])
    const optionsIdDitric = ref([])
    const optionsIdWard = ref([])
    const locale = computed(() => VueI18n.locale).value
    const isActiveAddMorePhone = ref(true)
    const customerData = ref({
      firstName: '',
      lastName: '',
      emailAddress: '',
      nickName: '',
      phoneNumber: '',
      id: getUserData().employeeData.id,
      gender: 'MALE',
      photo: null,
      description: null,
      birthDay: null,
      createdAt: null,
      addressCountry: null,
      addressCity: null,
      note: null,
      addressDistrict: null,
      addressWard: null,
      addressStreet: null,
      contacts: null,
      // backupPhoneNumbers: [],
      identifyNumber: '',
      passPortConfig: null,
      passportNumber: '',
      identifyConfig: {
        startDate: null,
        endDate: null,
        placeOfIssue: '',
        nationality: '',
      },
    })
    const nationalOptions = computed(() => store.getters['globalConfig/getAllCountries'])

    const customerDataInfo = ref([customerData.value])
    customerDataInfo.value = customerDataInfo.value.map(customer => ({
      ...customer,
      passPortConfig: {
        startDate: null,
        endDate: null,
        placeOfIssue: '',
        nationality: '',
      },
      identifyConfig: {
        startDate: null,
        endDate: null,
        placeOfIssue: '',
        nationality: '',
      },
    }))
    const bvModal = root.$bvModal
    const { getValidationState } = formValidation(customerData)
    const modalId = 'modal-customer-edit-social-network'
    const editSocialNetwork = async data => {
      if (!data) return

      await new Promise(resolve => resolve())

      bvModal.show(modalId)
    }
    const onClickQrScanHandle = id => {
      bvModal.show(id)
    }
    // watch(
    //   () => [...customerData.value.backupPhoneNumbers],
    //   () => {
    //     const backupLength = customerData.value.backupPhoneNumbers.length
    //     if (backupLength === 0) isActiveAddMorePhone.value = true
    //     else if (backupLength >= 4) isActiveAddMorePhone.value = false
    //     else {
    //       isActiveAddMorePhone.value = true
    //       customerData.value.backupPhoneNumbers.forEach(
    //         async backupPhone => {
    //           const { valid } = await validate(
    //             backupPhone,
    //             `required|phoneNumber|isUnique:customer,phonenumber|duplicatePhone:${
    //               customerData.value.phoneNumber
    //             },${customerData.value.backupPhoneNumbers.toString()}`,
    //           )
    //           if (!valid) isActiveAddMorePhone.value = false
    //         },
    //       )
    //     }
    //   },
    // )
    // const getValidateIdentify = ide => {
    //   if (ide) {
    //     let isHaveIdentify = false
    //     if (ide?.identifyConfig?.startDate
    //     || ide?.identifyConfig?.endDate
    //     || ide?.identifyConfig?.placeOfIssue
    //     || ide?.identifyConfig?.nationality
    //     || ide?.identifyNumber) { isHaveIdentify = true }
    //     return isHaveIdentify ? 'required' : ''
    //   }

    //   return ''
    // }
    // const getValidatePassport = pax => {
    //   if (pax) {
    //     let isHavePassport = false
    //     if (pax?.passportConfig?.startDate
    //     || pax?.passportConfig?.endDate
    //     || pax?.passportConfig?.placeOfIssue
    //     || pax?.passportConfig?.nationality
    //     || pax?.passportNumber) { isHavePassport = true }
    //     return isHavePassport ? 'required' : ''
    //   }

    //   return ''
    // }

    const cleanEmptyFields = obj => Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== '' && value !== null && value !== undefined),
    )
    watch(
      () => [props.mode, nationalOptions],
      () => {
        if (props.mode === 'edit' && nationalOptions.value?.length) {
          customerDataInfo.value = {
            ...props.dataCustomerEdit,
            passportConfig: cleanEmptyFields({
              startDate: props.dataCustomerEdit?.passportConfig?.startDate || null,
              endDate: props.dataCustomerEdit?.passportConfig?.endDate || null,
              nationality: nationalOptions.value.find(item => item.longCode === props.dataCustomerEdit?.passportConfig?.nationality) || props.dataCustomerEdit?.passportConfig?.nationality,
              placeOfIssue: nationalOptions.value.find(item => item.longCode === props.dataCustomerEdit?.passportConfig?.placeOfIssue) || props.dataCustomerEdit?.passportConfig?.placeOfIssue,
            }),
            identifyConfig: cleanEmptyFields({
              startDate: props.dataCustomerEdit?.identifyConfig?.startDate || null,
              endDate: props.dataCustomerEdit?.identifyConfig?.endDate || null,
              placeOfIssue: props.dataCustomerEdit?.identifyConfig?.placeOfIssue || '',
              nationality: props.dataCustomerEdit?.identifyConfig?.nationality || '',
            }),

          }
        } else {
          customerDataInfo.value = {
            firstName: '',
            lastName: '',
            emailAddress: '',
            nickName: '',
            phoneNumber: '',
            id: getUserData().employeeData?.id || null,
            gender: 'MALE',
            photo: null,
            description: null,
            birthDay: null,
            note: null,
            createdAt: null,
            addressCountry: null,
            addressCity: null,
            addressDistrict: null,
            addressWard: null,
            addressStreet: null,
            contacts: null,
            // backupPhoneNumbers: [],
            identifyNumber: '',
            passportNumber: '',
            identifyConfig: cleanEmptyFields({
              startDate: null,
              endDate: null,
              placeOfIssue: '',
              nationality: '',
            }),
            passportConfig: cleanEmptyFields({
              startDate: null,
              endDate: null,
              placeOfIssue: '',
              nationality: '',
            }),
          }
        }
        emit('add-customer-data-modal', customerDataInfo.value)
      },
      { immediate: true, deep: true },
    )
    // const addMorePhoneHandle = () => {
    //   customerDataInfo.value.backupPhoneNumbers.push('')
    // }
    // const removePhoneHandle = index => {
    //   customerDataInfo.value.backupPhoneNumbers.splice(index, 1)
    // }
    const isEditActive = computed(() => {
      const val = props.readonlys === true ? '' : '(*)'
      return val
    })

    const fetchCountries = async () => {
      try {
        const res = await apiCustomer.getAdministrativeUnits({ parentId: 1, page: 1, size: 63 })
        optionsIdCity.value = res.data.items
      } catch (e) {
        console.error('Error fetch countries', e)
      }
    }
    if (customerDataInfo.value.addressCity) {
      apiCustomer.getAdministrativeUnits({ parentId: customerDataInfo.value.addressCity.id })
        .then(res => {
          optionsIdCity.value = res.data.items
        })
        .catch(err => {
          console.error(err)
        })
    }
    else if (customerDataInfo.value.addressDistrict) {
      apiCustomer.getAdministrativeUnits({ parentId: customerDataInfo.value.addressDistrict.id })
        .then(res => {
          optionsIdDitric.value = res.data.items
        })
        .catch(err => {
          console.error(err)
        })
    }
    else if (customerDataInfo.value.addressDistrict) {
      apiCustomer.getAdministrativeUnits({ parentId: customerDataInfo.value.addressDistrict.id })
        .then(res => {
          optionsIdWard.value = res.data.items
        })
        .catch(err => {
          console.error(err)
        })
    }
    watch(
      () => customerDataInfo.value.addressCity,
      newValue => {
        if (!newValue) {
          optionsIdDitric.value = []
          optionsIdWard.value = []
          customerDataInfo.value.addressDistrict = null
          customerDataInfo.value.addressWard = null
        } else {
          const fetchDitric = async () => {
            try {
              const res = await apiCustomer.getAdministrativeUnits({ parentId: newValue?.id || newValue })
              optionsIdDitric.value = res.data.items
              customerDataInfo.value.addressDistrict = null
              customerDataInfo.value.addressWard = null
            } catch (e) {
              console.error('Error fetch countries', e)
            }
          }
          fetchDitric()
        }
      },
    )
    watch(
      () => customerDataInfo.value.addressDistrict,
      newValue => {
        if (!newValue) {
          optionsIdWard.value = []
          customerDataInfo.value.addressWard = null
        } else {
          const fetchWard = async () => {
            try {
              const res = await apiCustomer.getAdministrativeUnits({ parentId: newValue?.id || newValue })
              optionsIdWard.value = res.data.items
            } catch (e) {
              console.error('Error fetch countries', e)
            }
          }
          fetchWard()
        }
      },
    )

    onMounted(() => {
      fetchCountries()
    })
    return {
      locale,
      customerData,
      isEditActive,
      readonly,
      getValidationState,
      dateTime,
      formatCurrency,
      genderOptions,
      Vietnamese,
      trimInput,
      isActiveAddMorePhone,
      optionsIdCity,
      optionsIdDitric,
      fetchCountries,
      optionsIdWard,
      editSocialNetwork,
      flatPickr,
      // addMorePhoneHandle,
      // removePhoneHandle,
      getDate,
      customerDataInfo,
      onClickQrScanHandle,
    }
  },
}
</script>

<style lang="scss" scoped>
.fixed-width-select{
  width: 100%;
  min-width: 190px;
}
.fixed-feedback {
  min-height: 20px;
  line-height: 20px;
}
.card {
  box-shadow: none;
}

</style>
